html {
    padding: 0;
    
    @include breakpoint(medium) {
        padding: $global-padding;
    }
}

a {
    &:hover {
        text-decoration: underline;
    }
}
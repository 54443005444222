.app-exhibition {
  display: block;
}

.app-exhibition-body {
  display: block;
}

.app-exhibition-body-content {
  transition: all $offcanvas-transition-length $offcanvas-transition-timing;
  overflow-y: auto;
  flex: 1 1 0;
  padding: 20px;
  background-color: $white;

  .callout {
    background-color: transparent;
  }
}

.app-exhibition-main-nav {
  .app-exhibition-main-nav-content {
    padding: 1rem 0;
    border-bottom: 3px solid $black;
    text-align: center;
  
    &, .menu {
      background-color: $white;
    }

    .menu {      
      display: inline;

      li {
        display: inline-block;

        a, a:visited {
          color: $black;
          font-size: 90%;
          padding: .6rem;

          @include breakpoint(medium) {
            font-size: 110%; 
            padding: 1rem;
          }
          
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.app-exhibition-footer {
  display: block;
  font-size: 80%;
}
